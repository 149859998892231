export default {
  footer: {
    telegram: "Community & team support on Telegram",
    aboutDfuse: "dfuse is an open source blockchain data platform",
    copyright: "© 2018-{{year}} Platypus ",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "/",
    termsOfServices: "Terms of Service",
    termsOfServicesLink: "/",
  },
}
