export default {
  footer: {
    // telegram: "Telegram 社区&团队支持群",
    aboutDfuse: "dfuse 是一个开源区块链数据平台",
    copyright: "© 2018-{{year}} 鸭嘴兽 版权所有",
    privacyPolicy: "隐私政策",
    privacyPolicyLink: "/",
    termsOfServices: "服务条款",
    termsOfServicesLink: "/",
  },
}
