import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import { t } from "../../i18n"
import { Config, EosqNetwork } from "../../models/config"
import { SettingsSelector } from "./settings-selector"

interface Props extends RouteComponentProps<{}> {
  variant: "light" | "dark"
}

interface State {
  network: string
}

class NetworkSelectorContainer extends React.Component<Props, State> {
  availableNetworks = Config.available_networks
    .filter((network: EosqNetwork) =>
      !process.env.REACT_APP_EOSQ_CURRENT_NETWORK
        ? true
        : network.for?.includes(process.env.REACT_APP_EOSQ_CURRENT_NETWORK) ||
          network.id === process.env.REACT_APP_EOSQ_CURRENT_NETWORK
    )
    .sort((network: EosqNetwork, ref: EosqNetwork) =>
      network.is_test || network.name > ref.name ? 1 : -1
    )
  constructor(props: Props) {
    super(props)
    this.state = { network: Config.network_id }
  }

  onSelectNetwork = (value: string) => {
    if (value === this.state.network) {
      return
    }

    const selectedNetwork = Config.available_networks.find(
      (network: EosqNetwork) => network.id === value
    )
    if (selectedNetwork) window.location.href = selectedNetwork.url
  }

  render() {
    const networks = this.availableNetworks.map((network: EosqNetwork) => {
      return {
        label: t(`core.networkOptions.${network.id.replace("-", "_")}`, {
          defaultValue: network.name,
        }),
        value: network.id,
      }
    })

    return (
      <SettingsSelector
        options={networks}
        currentOption={Config.network_id}
        onSelect={this.onSelectNetwork}
        variant={this.props.variant}
      />
    )
  }
}

export const NetworkSelector = withRouter(NetworkSelectorContainer)
