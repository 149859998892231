export default {
  banner: {
    eos_usd: "PLATYPUS (in USD)",
    head_block: "head block",
    head_block_producer: "Current Producer",
    head_block_producer_tooltip: "Producer of the last block",
    irreversible_block_tooltip: "Last irreversible block",
    irreversible_block: "irreversible block",
    transactions: "transactions",
    tooltip: {
      last_24h_change: "Last 24h change (source: binance.com)",
    },
  },
}
