export default {
  banner: {
    eos_usd: "PLATYPUS (美元)",
    head_block: "当前区块数量",
    head_block_producer: "当前出块节点",
    head_block_producer_tooltip: "上一个出块节点",
    irreversible_block_tooltip: "上一个不可逆区块",
    irreversible_block: "不可逆区块",
    transactions: "交易",
    votes: "投票 （单位：PLATYPUS)",
    votes_tooltip: "用于投票的总抵押PLATYPUS",
    tooltip: {
      last_24h_change: "前24小时内的更改（来源：binance.com)",
    },
  },
}
